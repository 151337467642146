import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I'm an autodidactic Software Developer, experienced in JavaScript (React.js, Redux, Node, D3), Ruby on Rails, SQL, Git, HTML5, CSS3, and more.  I currently work as a Software Developer and Support Engineer (and sometimes Devops and PM) to support internal tools; It's awesome. `}</p>
    </blockquote>
    <p>{`Before that for several years I worked with a team of software engineers to design the robotics component of a coding studio for an art and technology education program. Also, pretty frekin awesome.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      