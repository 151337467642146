import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Linkapedia" link="https://www.linkapedia.app" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A frontend JavaScript project that uses D3.js to gamify a visualization of Wikipedia links - No big deal
    </ProjectCard>
    <ProjectCard title="Matsana" link="http://www.matsana.io" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  My first project, and created in 7 days; On the 8th day I took a break - Full-Stack Ruby on Rails with React and PostgreSQL database, an Asana clone.
    </ProjectCard>
    <ProjectCard title="Fly-To" link="http://www.fly-to.wiki" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Team effort to build a budget oriented flight search engine, with user controlled map schemas - The group no longer updates the site, but I keep it for sentimental purposes. Good times, good times.
    </ProjectCard>
    <ProjectCard title="Resumé" link="https://drive.google.com/file/d/1dlgfNCc6aw6wIWJoxoz9l5e5WjnvDzVv/view?usp=sharing" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  A snapshot of where I came from, and my experience in Software Development. No joke.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      