/** @jsx jsx */
import { Box, useColorMode, jsx } from "theme-ui"

const Footer = () => {
    // const [colorMode, setColorMode] = useColorMode()
    // const isDark = colorMode === `dark`
    // const toggleColorMode = (e, any) => {
    //     setColorMode(isDark ? `light` : `dark`)
    // }

    return null
        // <Box as="footer" variant="footer">
        //     <button
        //         sx={{ variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`, mb: 3 }}
        //         onClick={toggleColorMode}
        //         type="button"
        //         aria-label="Toggle dark mode"
        //     >
        //         {isDark ? `Light` : `Dark`}
        //     </button>
        // </Box>
}

export default Footer